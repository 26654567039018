import React from 'react'
import loader from '../../assets/img/loader.gif'
import './Loader.scss'

function Loader() {
    return (
        <div className='loader'>
            <img src={loader} alt="Loading..." />
        </div>
    )
}

export default Loader