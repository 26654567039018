import React from "react"
import PathConstants from "../Routes/pathConstants";

const Demo = React.lazy(() => import("../Demo/Demo"));
const Login = React.lazy(() => import("../Login/Login"));
const Home = React.lazy(() => import("../components/Pages/Home/Home"));
const Items = React.lazy(() => import("../components/Pages/Items/Items"));
const Parties = React.lazy(() => import("../components/Pages/Parties/Parties"));
const Sale = React.lazy(() => import("../components/Pages/Sale/Sale"));
const Purchase = React.lazy(() => import("../components/Pages/Purchase/Purchase"));
const PurchaseBills = React.lazy(() => import("../components/Pages/Purchase/PurchaseBills/PurchaseBills"));
const PurchaseReturn = React.lazy(() => import("../components/Pages/Purchase/PaymentReturn/PaymentReturn"));
const SaleReturn = React.lazy(() => import("../components/Pages/Sale/SaleReturn/SaleReturn"));
const SaleInvoices = React.lazy(() => import("../components/Pages/Sale/SaleInvoices/SaleInvoices"));
const PaymentIn = React.lazy(() => import("../components/Pages/Account/PaymentIn/PaymentIn"));
const PaymentOut = React.lazy(() => import("../components/Pages/Account/PaymentOut/PaymentOut"));
const AddItems = React.lazy(() => import("../components/Pages/Items/AddItems/AddItems"));
const Print = React.lazy(() => import("../components/Pages/Settings/Print/Print"));
const AddSaleInvoices = React.lazy(() => import("../components/Pages/Sale/SaleInvoices/AddSaleInvoices/AddSaleInvoices"));
const AddCreditNote = React.lazy(() => import("../components/Pages/Sale/SaleReturn/AddCreditNote/AddCreditNote"));
const Expensive = React.lazy(() => import("../components/Pages/Expensive/Expensive"));
const AddExpensive = React.lazy(() => import("../components/Pages/Expensive/AddExpensive/AddExpensive"));
const AddDebitNote = React.lazy(() => import("../components/Pages/Purchase/PaymentReturn/AddDebitNote/AddDebitNote"));
const AddPurchaseBills = React.lazy(() => import("../components/Pages/Purchase/PurchaseBills/AddPurchaseBills/AddPurchaseBills"));
const BankCash = React.lazy(() => import("../components/Pages/BankCash/BankCash"));
const Profile = React.lazy(() => import("../components/Pages/Profile/Profile"));
const Report = React.lazy(() => import("../components/Pages/Reports/Report")); 
const SaleOrder = React.lazy(() => import("../components/Pages/Sale/SaleOrder/SaleOrder")); 
const SaleAddOrder = React.lazy(() => import("../components/Pages/Sale/SaleOrder/AddSaleOrder/AddSaleOrder")); 
const General = React.lazy(() => import("../components/Pages/Settings/General/General")); 
const TransactionMessage = React.lazy(() => import("../components/Pages/Settings/TransactionMessage/TransactionMessage")); 
const TaxesGst = React.lazy(() => import("../components/Pages/Settings/TaxesGst/TaxesGst")); 

const routes = [
    { path: PathConstants.Demo, element: <Demo /> },
    { path: PathConstants.Login, element: <Login /> },
    { path: PathConstants.LoginPage, element: <Login /> },
    { path: PathConstants.Home, element: <Home /> },
    { path: PathConstants.Items, element: <Items /> },
    { path: PathConstants.Parties, element: <Parties /> },
    { path: PathConstants.Sale, element: <Sale /> },
    { path: PathConstants.Purchase, element: <Purchase /> },
    { path: PathConstants.PurchaseBills, element: <PurchaseBills /> },
    { path: PathConstants.PurchaseReturn, element: <PurchaseReturn /> },
    { path: PathConstants.SaleReturn, element: <SaleReturn /> },
    { path: PathConstants.SaleInvoices, element: <SaleInvoices /> },
    { path: PathConstants.PaymentIn, element: <PaymentIn /> },
    { path: PathConstants.PaymentOut, element: <PaymentOut /> },
    { path: PathConstants.AddItems, element: <AddItems /> },
    { path: PathConstants.Print, element: <Print /> },
    { path: PathConstants.AddSaleInvoices, element: <AddSaleInvoices /> },
    { path: PathConstants.AddCreditNote, element: <AddCreditNote /> },
    { path: PathConstants.Expensive, element: <Expensive /> },
    { path: PathConstants.AddExpensive, element: <AddExpensive /> },
    { path: PathConstants.AddDebitNote, element: <AddDebitNote /> },
    { path: PathConstants.AddPurchaseBills, element: <AddPurchaseBills /> },
    { path: PathConstants.BankCash, element: <BankCash /> },
    { path: PathConstants.Profile, element: <Profile /> },
    { path: PathConstants.Report, element: <Report /> }, 
    { path: PathConstants.SaleOrder, element: <SaleOrder /> }, 
    { path: PathConstants.SaleAddOrder, element: <SaleAddOrder /> }, 
    { path: PathConstants.General, element: <General /> }, 
    { path: PathConstants.TransactionMessage, element: <TransactionMessage /> }, 
    { path: PathConstants.TaxesGst, element: <TaxesGst /> }, 
];

export default routes;