const PathConstants = {
    Demo: "demo",
    Accohisab: "",
    Login: "/",
    LoginPage: "login",
    Home: "home",
    Items: "items",
    Parties: "parties",
    Sale: "sale",
    Purchase: "purchase",
    PurchaseBills: "purchasebills",
    PurchaseReturn: "PurchaseReturn",
    SaleReturn: "salereturn",
    SaleInvoices: "saleinvoices",
    PaymentIn: "paymentin",
    PaymentOut: "paymentout",
    AddItems: "additems",
    Print: "print",
    AddSaleInvoices: "addSaleInvoices",
    AddCreditNote: "addCreditNote",
    Expensive: "expensive",
    AddExpensive: "expensive/addExpensive",
    AddDebitNote: "addDebitNote",
    AddPurchaseBills: "addPurchaseBills",
    BankCash: "bank-cash",
    Profile: "profile",
    Report: "report", 
    SaleOrder: "saleorder", 
    SaleAddOrder: "saleaddorder", 
    General: "general", 
    TransactionMessage: "transaction_message", 
    TaxesGst: "taxes_gst", 
};

export default PathConstants;