import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function KeyEventHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const keyMap = {
      S: '/addSaleInvoices',
      P: '/addPurchaseBills',
      R: '/addCreditNote',
      I: '/paymentin',
      O: '/paymentout',
      E: '/expensive/addExpensive',
      L: '/addDebitNote',
      F: '/saleaddorder', 
      A: '/additems', 
    };

    const handleKeyDown = (event) => {
      if (event.altKey) {
        const route = keyMap[event.key.toUpperCase()];
        if (route) {
          event.preventDefault();
          navigate(route);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return null;
}

export default KeyEventHandler;
