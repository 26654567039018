import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import './SnackBarNotification.scss';

function SnackBarNotification(props) {
    const toast = useRef(null);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (props.open && !showToast) {
            const severity = props.color;
            const color = capitalizeFirstLetter(props.color);
            const text = props.text;

            const customIcon = (
                <React.Fragment>
                    {severity === "success" ? (
                        <i className="pi pi-check"></i>
                    ) : severity === "warning" ? (
                        <i className="pi pi-exclamation-triangle"></i>
                    ) : severity === "error" ? (
                        <i className="pi pi-times-circle"></i>
                    ) : ""}
                </React.Fragment>
            ); 
            
            toast.current.clear();

            toast.current.show({
                severity: severity,
                summary: color,
                detail: text,
                life: 2000,
                icon: customIcon,
                closable: true
            });

            setShowToast(true);

            props.sankbar(false);

            setTimeout(() => {
                setShowToast(false);
            }, 3000); // 1000 delay
        }
    }, [props.open, props.color, props.text, showToast]);

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return (
        <Toast ref={toast} position='top-right' />
    );
}

export default SnackBarNotification;
