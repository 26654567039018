import React, { useEffect, useState } from 'react';
import './AddParties.scss';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Get, Post } from '../../../../Config/config';
import { RadioButton } from 'primereact/radiobutton';
import SnackBarNotification from '../../../SnackBarNotification/SnackBarNotification';
import Loader from '../../../Loader/Loader';
import { formatDate, parseDate } from '../../../GlobalFunction/GlobalFunction';
import { Checkbox } from 'primereact/checkbox';

function AddParties(props) {

    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");
    // Loader
    const [loader, setLoader] = useState(false);

    const [partyId, setPartyId] = useState(0);
    const [partyName, setPartyName] = useState('');
    const [partyNameValid, setPartyNameValid] = useState(false);
    const [GSTIN, setGSTIN] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gstType, setGstType] = useState('');
    const [state, setState] = useState(null);
    const [stateName, setStateName] = useState([]);
    const [emailID, setEmailID] = useState('');
    const [remark, setRemark] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');
    const [openingbalance, setOpeningbalance] = useState('');
    const [asOfDate, setAsOfDate] = useState(new Date());
    const [creditchecked, setCreditchecked] = useState(false);
    const [creditLimit, setCreditLimit] = useState('');
    const [onSave, setOnSave] = useState(true);
    const [ingredient, setIngredient] = useState('ToReceive');

    const [smsToParty, setSmsToParty] = useState(false);

    const [gstTypes] = useState([
        { name: 'Unregistered/Consumer' },
        { name: 'Registered Business - Regular' },
        { name: 'Registered Business - Composition' }
    ]);

    const [filteredgstType, setFilteredgstType] = useState(null);
    const [filteredState, setFilteredState] = useState(null);

    useEffect(() => {
        if (props.visible && stateName.length === 0) {
            getStateName();
        }
    }, [props.visible, stateName.length]);

    const getStateName = () => {
        if (stateName.length === 0) {
            Get('StatesNameApi').then((res) => {
                if (res.status === 1) {
                    if (res.UserData) {
                        setStateName(res.UserData);
                    };
                };
            });
        };
    };

    useEffect(() => {
        if (props.selectedParty) {
            fillFormData(props.selectedParty);
        }
    }, [props.selectedParty]);

    const fillFormData = (party) => {
        setPartyId(party.PartyId);
        setPartyName(party.PartyName);
        setGSTIN(party.GSTIN);
        setPhoneNumber(party.MobileNo);
        setGstType(party.GSTType === "" ? null : { name: party.GSTType });
        setState(party.State === "" || null ? null : party.State);
        setEmailID(party.EmailId);
        setRemark(party.Remark);
        setBillingAddress(party.BillingAddress);
        setShippingAddress(party.ShippingAddress);
        setOpeningbalance(party.OpeningBalance === 0 ? "" : Math.abs(party.OpeningBalance));
        setAsOfDate(parseDate(party.AsOfDate));
        const isCreditLimitSet = party.CreditLimit !== 0 && party.CreditLimit !== '';
        setCreditchecked(isCreditLimitSet);
        setCreditLimit(party.CreditLimit === 0 ? '' : party.CreditLimit);
        setOnSave(false);
        setSmsToParty(party.PaymentReminderMSG);
        
        if (party.OpeningBalance < 0) {
            setIngredient('ToPay');
        } else {
            setIngredient('ToReceive');
        }
    };

    const handleChange = (value, name) => {
        if (name === 'partyname') {
            setPartyName(value);
            setOnSave(value === '');
        } else if (name === 'GSTIN') {
            setGSTIN(value);
        } else if (name === 'phonenumber') {
            setPhoneNumber(value);
        } else if (name === 'gstType') {
            setGstType(value);
        } else if (name === 'state') {
            setState(value);
        } else if (name === 'emailID') {
            setEmailID(value);
        } else if (name === 'remark') {
            setRemark(value);
        } else if (name === 'billingaddress') {
            setBillingAddress(value);
        } else if (name === 'shippingaddress') {
            setShippingAddress(value);
        } else if (name === 'openingbalance') {
            setOpeningbalance(value);
            // if (value < 0) {
            //     setIngredient('ToPay');
            // } else {
            //     setIngredient('ToReceive');
            // }
        } else if (name === 'asofdate') {
            setAsOfDate(value);
        } else if (name === 'radio-credit') {
            setCreditchecked(value);
            if (value === false) {
                setCreditLimit("");
            }
        } else if (name === 'creditlimit') {
            setCreditLimit(value);
        };
    };

    const onSaveParties = () => {

        if (partyName) {
            setLoader(true);
            Post('Party/Add', {
                PartyId: partyId,
                PartyName: partyName,
                GSTIN: GSTIN,
                MobileNo: phoneNumber || '',
                GSTType: gstType?.name || '',
                State: state?.name || '',
                EmailId: emailID,
                BillingAddress: billingAddress,
                ShippingAddress: shippingAddress,
                Remark: remark,
                OpeningBalance: ingredient === 'ToPay' ? -Math.abs(openingbalance) : Math.abs(openingbalance),
                AsOfDate: asOfDate ? formatDate(asOfDate) : "",
                CreditLimit: creditLimit,
                CreditLimitToggle: creditLimit.length > 0 ? 1 : 0,
                PaymentReminderMSG: smsToParty
            }).then((res) => {
                setLoader(false);
                if (res) {
                    if (res.status === 1) {
                        if (props.partyName) {
                            props.partyName(partyName);
                        }
                        if (props.partyNumber) {
                            props.partyNumber(phoneNumber);
                        }
                        if (props.setVisible) {
                            props.setVisible(false);
                        }
                        resetForm();
                        setSankbar(true);
                        setSankbarText("New transaction saved successfully.");
                        setSankbarColor("success");
                    } else {
                        setSankbar(true);
                        setSankbarText(res.message);
                        setSankbarColor("error");
                    }
                }
            });
        } else {
            setPartyNameValid(true);
        };
    };

    const resetForm = () => {
        setPartyId(0);
        setPartyName('');
        setPartyNameValid(false);
        setGSTIN('');
        setPhoneNumber('');
        setGstType('');
        setState('');
        setEmailID('');
        setRemark('');
        setBillingAddress('');
        setShippingAddress('');
        setOpeningbalance('');
        setAsOfDate(new Date());
        setCreditchecked(false);
        setCreditLimit('');
        setOnSave(true);
        setIngredient('ToReceive');
    };

    const footer = () => (
        <Button label="Save" raised disabled={onSave} severity='info' onClick={() => onSaveParties()} />
    );

    const searchItems = (event, list, setFiltered) => {
        let query = event.query ? event.query.toLowerCase() : '';
        let _filteredItems = list.filter(item => item.name.toLowerCase().includes(query));
        setFiltered(_filteredItems);
    };

    const searchgstTypes = (event) => {
        searchItems(event, gstTypes, setFilteredgstType);
    };

    const searchState = (event) => {
        const query = event.query.toLowerCase();
        let filtered = stateName.filter(item => item.name.toLowerCase().includes(query));

        setFilteredState(filtered);
    };

    return (
        <React.Fragment>
            <Dialog
                header="Add Party"
                id='addParty'
                visible={props.visible}
                maximizable
                onHide={() => { props.setVisible(false); resetForm() }}
                footer={footer}
            >
                <div className='topView'>
                    <FloatLabel>
                        <InputText
                            keyfilter=''
                            value={partyName}
                            invalid={partyNameValid}
                            onChange={(e) => { handleChange(e.target.value, 'partyname'); }}
                            name="Party Name*"
                            id="partyname"
                            autoFocus={true}
                            autoComplete='off'
                        />
                        <label htmlFor="partyname">Party Name*</label>
                    </FloatLabel>
                    <FloatLabel>
                        <Tooltip target=".custom-target-icon" />
                        <InputText
                            keyfilter=''
                            value={GSTIN}
                            onChange={(e) => handleChange(e.target.value, 'GSTIN')}
                            name="GSTIN"
                            id="GSTIN"
                            maxLength={25}
                            autoComplete='off'
                        />
                        <i className="custom-target-icon pi pi-info" data-pr-tooltip="Fill GSTIN to verify & fetch party data." data-pr-position="bottom"></i>
                        <label htmlFor="GSTIN">GSTIN</label>
                    </FloatLabel>
                    <FloatLabel>
                        <InputText
                            keyfilter={'num'}
                            value={phoneNumber}
                            onChange={(e) => handleChange(e.target.value, 'phonenumber')}
                            name="Phone Number"
                            id="phonenumber"
                            maxLength={10}
                            autoComplete='off'
                        />
                        <label htmlFor="phonenumber">Phone Number</label>
                    </FloatLabel>
                </div>

                <TabView>
                    <TabPanel header="GST & Address">
                        <div className='gst-address-flex'>
                            <div className='inputfield'>
                                <FloatLabel>
                                    <AutoComplete
                                        value={gstType}
                                        suggestions={filteredgstType}
                                        completeMethod={searchgstTypes}
                                        onChange={(e) => handleChange(e.value, 'gstType')}
                                        field="name"
                                        dropdown
                                        forceSelection
                                        autoComplete='off'
                                    />
                                    <label htmlFor="gstType">GST Type</label>
                                </FloatLabel>
                                <FloatLabel>
                                    <AutoComplete
                                        value={state}
                                        suggestions={filteredState}
                                        completeMethod={searchState}
                                        onChange={(e) => handleChange(e.value, 'state')}
                                        dropdown
                                        field="name"
                                        forceSelection
                                        autoComplete='off'
                                        id='state'
                                    />
                                    <label htmlFor="state">State</label>
                                </FloatLabel>
                                <FloatLabel>
                                    <InputText
                                        keyfilter='email'
                                        value={emailID}
                                        onChange={(e) => handleChange(e.target.value, 'emailID')}
                                        id='emailID'
                                        autoComplete='off'
                                    />
                                    <label htmlFor="emailID">Email ID</label>
                                </FloatLabel>
                                <FloatLabel>
                                    <InputTextarea
                                        value={remark}
                                        onChange={(e) => handleChange(e.target.value, 'remark')}
                                        rows={4} cols={20}
                                        id='remark'
                                        autoComplete='off'
                                    />
                                    <label htmlFor="remark">Remark</label>
                                </FloatLabel>
                            </div>

                            <div className='address'>
                                <FloatLabel>
                                    <InputTextarea
                                        value={billingAddress}
                                        onChange={(e) => handleChange(e.target.value, 'billingaddress')}
                                        rows={5} cols={30}
                                        autoComplete='off'
                                        id='billingaddress'
                                    />
                                    <label htmlFor="billingaddress">Billing Address</label>
                                </FloatLabel>
                                <FloatLabel>
                                    <InputTextarea
                                        value={shippingAddress}
                                        onChange={(e) => handleChange(e.target.value, 'shippingaddress')}
                                        rows={5} cols={30}
                                        id='shippingaddress'
                                        autoComplete='off'
                                    />
                                    <label htmlFor="shippingaddress">Shipping Address</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Credit & Balance">
                        <div className='credit-balance'>
                            {/* <FloatLabel>
                                <InputText
                                    keyfilter={/^\d*\.?\d*$/}
                                    id="openingbalance"
                                    value={openingbalance}
                                    onChange={(e) => handleChange(e.target.value, 'openingbalance')}
                                    autoComplete='off'
                                    maxLength={10}
                                />
                                <label htmlFor="openingbalance">Opening Balance</label>
                            </FloatLabel> */}
                            <FloatLabel>
                                <Calendar
                                    value={asOfDate}
                                    onChange={(e) => handleChange(e.target.value, 'asofdate')}
                                    dateFormat="dd/mm/yy"
                                    id='asofdate'
                                    showIcon
                                    autoComplete='off'
                                />
                                <label htmlFor="asofdate">As Of Date</label>
                            </FloatLabel>

                            <div className="p-field-checkbox">
                                <Checkbox inputId="smsToParty" checked={smsToParty} onChange={e => setSmsToParty(e.checked)} />
                                <label htmlFor="smsToParty">Balance Reminder Automatic MSG</label>
                            </div>
                        </div>
                        {/* <div className="RadioButton">
                            <div className="topay">
                                <RadioButton
                                    inputId="ingredient1"
                                    name="payment"
                                    value="ToPay"
                                    onChange={(e) => setIngredient(e.value)}
                                    checked={ingredient === 'ToPay'} />
                                <label htmlFor="payment" className="ml-2">To Pay</label>
                            </div>
                            <div className="toreceive">
                                <RadioButton
                                    inputId="ingredient2"
                                    name="payment"
                                    value="ToReceive"
                                    onChange={(e) => setIngredient(e.value)}
                                    checked={ingredient === 'ToReceive'} />
                                <label htmlFor="payment" className="ml-2">To Receive</label>
                            </div>
                        </div> */}
                        <div className="credit-radio-button">
                            <div className='credit-info'>
                                <p>Credit Limit </p>
                            </div>
                            <div className='custom-limit'>
                                <p>No Limit</p>
                                <InputSwitch
                                    checked={creditchecked}
                                    onChange={(e) => handleChange(e.target.value, 'radio-credit')}
                                    id='radio-credit'
                                />
                                <p>Custom Limit</p>
                            </div>
                            {creditchecked &&
                                <FloatLabel>
                                    <InputText
                                        keyfilter={'num'}
                                        value={creditLimit}
                                        onChange={(e) => handleChange(e.target.value, 'creditlimit')}
                                        name="Credit Limit"
                                        id="creditlimit"
                                        maxLength={10}
                                        autoComplete='off'
                                    />
                                    <label htmlFor="Number">Credit Limit</label>
                                </FloatLabel>}
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>

            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />

            {loader ? <Loader /> : ""}
        </React.Fragment>

    )
}

export default AddParties;