import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Get, Post } from '../../Config/config';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';
import Loader from '../Loader/Loader';
import './UserInfoDialog.scss'
import { AutoComplete } from 'primereact/autocomplete';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';

const UserInfoDialog = ({ visible, onHide, onSave }) => {

    const [businessName, setBusinessName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [gstin, setGstin] = useState('');
    const [emailId, setEmailId] = useState('');

    const [businessAddress, setBusinessAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [photoNameLink, setPhotoNameLink] = useState('');
    const [hasImage, setHasImage] = useState(false);
    const fileInputRef = useRef(null);

    const [imageFile2, setImageFile2] = useState(null);
    const [signecherNameLink, setSignecherNameLink] = useState('');
    const [imagePreview2, setImagePreview2] = useState(null);
    const [hasImage2, setHasImage2] = useState(false);
    const fileInputRef2 = useRef(null);

    const [stateName, setStateName] = useState(null);
    const [filteredStateName, setFilteredStateName] = useState([]);
    const [allStateNames, setAllStateNames] = useState([]);

    // SnackBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");
    // Loader
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            userFill();
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    const userFill = async () => {
        try {
            const response = await Post('Firm/Fill', { FirmId: 1 });

            if (response && Array.isArray(response.UserData) && response.UserData.length > 0) {
                const userData = response.UserData[0];
                if (userData) {
                    setBusinessName(userData.BusinessName || '');
                    setMobileNo(userData.MobileNo || '');
                    setGstin(userData.GSTIN || '');
                    setEmailId(userData.EmailId || '');
                    setBusinessAddress(userData.BusinessAddress || '');
                    setPincode(userData.Pincode || '');
                    setStateName(userData.State || '');
                    setBusinessDescription(userData.BusinessDescription || '');
                    setBusinessType(userData.BusinessType || '');
                    setBusinessCategory(userData.BusinessCategory || '');

                    if (userData.ProImageFilePath) {
                        const relativePath = userData?.ProImageFilePath
                        setImagePreview(relativePath);
                        setPhotoNameLink(userData.ProImageFilePath?.replace(/^http:\/\/[^\/]+\/?/, ''))
                        setHasImage(true);
                    }

                    if (userData.SigImageFilePath) {
                        const relativePath = userData?.SigImageFilePath;
                        setImagePreview2(relativePath);
                        setSignecherNameLink(userData.SigImageFilePath?.replace(/^http:\/\/[^\/]+\/?/, ''))
                        setHasImage2(true);
                    }
                } else {
                    console.error('User data is undefined');
                }
            } else {
                console.error('UserData is not available or empty');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // StatesName

    useEffect(() => {
        if (allStateNames.length === 0) {
            stateFill();
        }
    }, []);

    const stateFill = () => {
        Get('StatesNameApi').then((res) => {
            if (res) {
                if (res.status === 1) {
                    if (res.UserData) {
                        setAllStateNames(res.UserData);
                    };
                };
            };
        });
    };

    const searchStateName = (event) => {
        const query = event.query.toLowerCase();
        const filtered = allStateNames.filter(state => state.name.toLowerCase().includes(query));
        setFilteredStateName(filtered);
    };

    const handleChange = (value, name) => {
        if (name === 'stateName') {
            setStateName(value)
        }
    };

    const handleChangeClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {

            setImageFile(file); 
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setHasImage(true);
            };
            reader.readAsDataURL(file);
        }
    }; 

    const handleImageDelete = () => {
        setImageFile(null);
        setImagePreview(null);
        setHasImage(false);
    };

    const handleImageChange2 = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile2(file);
            const reader = new FileReader();
            reader.onloadend = () => {

                setImagePreview2(reader.result);
                setHasImage2(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChangeClick2 = () => {
        if (fileInputRef2.current) {
            fileInputRef2.current.click();
        }
    };

    const handleImageDelete2 = () => {
        setImageFile2(null);
        setImagePreview2(null);
        setHasImage2(false);
    };

    const userCondition = () => {

        if (!businessName || businessName === "") {
            setSankbar(true);
            setSankbarText("Business name cannot be empty.");
            setSankbarColor("warning");
            return false;
        } else if (!mobileNo || mobileNo === "") {
            setSankbar(true);
            setSankbarText("Mobile number cannot be empty.");
            setSankbarColor("warning");
            return false;
        } else if (!businessAddress || businessAddress === "") {
            setSankbar(true);
            setSankbarText("Business address cannot be empty.");
            setSankbarColor("warning");
            return false;
        }
        return true;
    }

    const handleSave = () => {

        const conditionsMet = userCondition();
        if (conditionsMet) {
            const formData = new FormData();
            formData.append('FirmId', 1);
            formData.append('BusinessName', businessName);
            formData.append('GSTIN', gstin);
            formData.append('MobileNo', String(mobileNo));
            formData.append('EmailId', emailId);
            formData.append('BusinessAddress', businessAddress);
            formData.append('Pincode', String(pincode));
            formData.append('State', typeof stateName === 'string' ? stateName : (stateName ? stateName.name : ""));
            formData.append('BusinessDescription', businessDescription);
            formData.append('BusinessType', businessType);
            formData.append('BusinessCategory', businessCategory);

            formData.append('isDefaultPro', imagePreview === null ? 1 : 0);
            formData.append('ProImageFilePath', imageFile ? imageFile : "");
            formData.append("ProImageLink", photoNameLink ? photoNameLink : '');

            formData.append('isDefaultSig', imagePreview2 === null ? 1 : 0);
            formData.append('SigImageFilePath', imageFile2 ? imageFile2 : "");
            formData.append("SigImageLink", signecherNameLink ? signecherNameLink : '');

            setLoader(true)
            Post('Firm/Add', formData)
                .then(response => {
                    setLoader(false)
                    if (response.status === 1) {
                        onSave();
                        onHide();
                    } else {
                        setSankbar(true);
                        setSankbarText(response.message);
                        setSankbarColor("error");
                    }
                })
                .catch(error => {
                    console.error('Error saving user information:', error);
                });
        }
    };

    return (
        <>
            <Dialog header="Business Information" visible={visible} onHide={onHide} footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-secondary" raised />
                    <Button label="Save" icon="pi pi-check" onClick={() => handleSave()} raised />
                </div>
            }
                id='user-info-dialog'
            >
                <div className="p-field">
                    <label htmlFor="businessName">Business Name*</label>
                    <InputText id="businessName" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="mobileNo">Mobile No*</label>
                    <InputText id="mobileNo" keyfilter={'num'} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} maxLength={10} />
                </div>
                <div className="p-field">
                    <label htmlFor="gstin">GSTIN</label>
                    <InputText id="gstin" value={gstin} onChange={(e) => setGstin(e.target.value)} maxLength={15} />
                </div>
                <div className="p-field">
                    <label htmlFor="emailId">Email ID</label>
                    <InputText id="emailId" type='email' value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="businessAddress">Business Address*</label>
                    <InputTextarea id="businessAddress" value={businessAddress} rows={3} cols={22} onChange={(e) => setBusinessAddress(e.target.value)} style={{ resize: "none" }} />
                </div>
                <div className="p-field">
                    <label htmlFor="pincode">Pincode</label>
                    <InputText id="pincode" keyfilter={'num'} value={pincode} onChange={(e) => setPincode(e.target.value)} maxLength={6} />
                </div>

                <div className="p-field">
                    <label htmlFor="state">State</label>
                    <AutoComplete
                        value={stateName}
                        suggestions={filteredStateName}
                        completeMethod={searchStateName}
                        field="name"
                        placeholder='Select state'
                        dropdown
                        onChange={(e) => handleChange(e.target.value, 'stateName')}
                        autoComplete='off'
                    />
                </div>

                <div className="p-field">
                    <label htmlFor="businessDescription">Business Description</label>
                    <InputText id="businessDescription" value={businessDescription} onChange={(e) => setBusinessDescription(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="businessType">Business Type</label>
                    <InputText id="businessType" value={businessType} onChange={(e) => setBusinessType(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="businessCategory">Business Category</label>
                    <InputText id="businessCategory" value={businessCategory} onChange={(e) => setBusinessCategory(e.target.value)} />
                </div>

                <div className="p-field">
                    <label htmlFor="proImage">Profile Image</label>
                    {!hasImage ? (
                        <div>
                            <input
                                type="file"
                                id="proImage"
                                ref={fileInputRef}
                                onChange={handleImageChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                            <Button onClick={handleChangeClick} severity="info" raised>Choose Image</Button>
                        </div>
                    ) : (
                        <div className='image-preview'>
                            <Image
                                src={imagePreview}
                                alt="Profile Preview"
                                preview
                                style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }}
                            />
                            <div style={{ marginTop: "10px" }}>
                                <Button onClick={handleImageDelete} severity="danger" raised>Delete</Button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="p-field">
                    <label htmlFor="signatureImage">Signature Image</label>
                    {!hasImage2 ? (
                        <div>
                            <input
                                type="file"
                                id="signatureImage"
                                ref={fileInputRef2}
                                onChange={handleImageChange2}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                            <Button onClick={handleChangeClick2} severity="info" raised>Choose Image</Button>
                        </div>
                    ) : (
                        <div className='image-preview'>
                            <Image
                                src={imagePreview2}
                                alt="Profile Preview"
                                preview
                                style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }}
                            />
                            <div style={{ marginTop: "10px" }}>
                                <Button onClick={handleImageDelete2} severity="danger" raised>Delete</Button>
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>

            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />

            {loader && <Loader />}
        </>
    );
};

export default UserInfoDialog;
