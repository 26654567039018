function parseDate(dateStr) {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
}

function formatDate(date) {
    if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
};

export { parseDate, formatDate }

export const validatePassword = (password) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const correctPassword = `GT${day}${month}`;

    if (password === correctPassword) {
        return { isValid: true, message: '' };
    } else {
        return { isValid: false, message: 'Incorrect password, please try again.' };
    }
};