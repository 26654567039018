import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Login.scss";
import logo from "./../assets/img/accologo.png";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FloatLabel } from 'primereact/floatlabel';
import { Post } from '../Config/config';
import SnackBarNotification from '../components/SnackBarNotification/SnackBarNotification';
import Loader from '../components/Loader/Loader';
import { SocketContext } from '../components/SocketContext/SocketContext';
import { Dialog } from 'primereact/dialog';

function Login() {
    const socket = useContext(SocketContext);

    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");
    // Loader
    const [loader, setLoader] = useState(false);

    const [otp, setOtp] = useState('');
    const [otpValid, setOtpValid] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [userData, setUserData] = useState(null);
    const [visible, setVisible] = useState(false);

    const [forgotPassword, setForgotPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [firstnameValid, setFirstnameValid] = useState(false);
    const [lastname, setLastname] = useState('');
    const [lastnameValid, setLastnameValid] = useState(false);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [register_username, setRegister_username] = useState('');
    const [register_usernameValid, setRegister_usernameValid] = useState(false);
    const [register_mobilenumber, setRegister_mobilenumber] = useState('');
    const [register_mobilenumberValid, setRegister_mobilenumberValid] = useState(false);
    const [newpassword, setNewpassword] = useState("");
    const [newpasswordValid, setNewpasswordValid] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [invalidCode, setInvalidCode] = useState('');
    const [invalidCodeValid, setInvalidCodeValid] = useState(false);
    const [enteredWhatsappOtp, setEnteredWhatsappOtp] = useState("");
    const [enteredMailOtp, setEnteredMailOtp] = useState("");
    const [otpStage, setOtpStage] = useState(false);
    const [mailotpValid, setMailotpValid] = useState(false);
    const [whatsAppotpValid, setWhatsAppotpValid] = useState(false);
    const [forgot_email, setForgot_email] = useState('');
    const [forgot_emailValid, setForgot_emailValid] = useState(false);

    const [type, setType] = useState("signUp");
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/" || window.location.pathname === "/login") {
            sessionStorage.removeItem("header");
            localStorage.removeItem('userData');
            localStorage.removeItem("login");
            localStorage.removeItem("ID");
            localStorage.removeItem("Error");
            navigate('/login');
        }

    }, [window.location.pathname]);

    // const [authStatus, setAuthStatus] = useState(null);

    // useEffect(() => {
    //     socket.on('authResponse', (data) => {
    //         setAuthStatus(data);
    //     });

    //     return () => {
    //         socket.off('authResponse');
    //     };
    // }, [socket]);

    const handleLoginSuccess = (res) => {
        localStorage.setItem("ID", res.token);
        localStorage.setItem("userData", JSON.stringify(res.UserData));
        sessionStorage.setItem("header", JSON.stringify(res.Header));
        localStorage.setItem("login", true);
        navigate(`/home`);
    };

    const handleLoginFailure = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem("login");
        localStorage.removeItem("ID");
        localStorage.setItem("login", false);
    };

    const handleLogin = async () => {
        if (!username || !password || !invalidCode) {
            setUsernameValid(!username);
            setPasswordValid(!password);
            setInvalidCodeValid(!invalidCode);
        } else {
            try {
                const credentials = {
                    CompanyCode: invalidCode,
                    Username: username,
                    Pass: password
                };

                socket.emit('authenticate', credentials);

                socket.on('otpSent', (res) => {
                    if (res && res.status === 1) {
                        setOtpSent(true);
                        setUserData(res);
                        setSankbar(true);
                        setSankbarText("OTP sent to your WhatsApp number.");
                        setSankbarColor("success");
                    } else {
                        setSankbar(true);
                        setSankbarText(res.message || "Failed to send OTP.");
                        setSankbarColor("error");
                        setLoader(false);
                    }
                });
                socket.on('authResponse', (res) => {
                    if (res && res.status === 1) {
                        handleLoginSuccess(res);
                        onHandelReset();
                        setSankbar(true);
                        setSankbarText("Login successful.");
                        setSankbarColor("success");
                    } else {
                        handleLoginFailure();
                        setSankbar(true);
                        setSankbarText(res.message || "Authentication failed.");
                        setSankbarColor("error");
                        setLoader(false);
                    }
                });
            } catch (error) {
                setSankbar(true);
                setSankbarText(error.message || "An error occurred during login.");
                setSankbarColor("error");
                setLoader(false);
                console.error(error);
            }
        }
    };

    const handleOtpVerification = () => {
        if (!otp) {
            setOtpValid(true);
        } else {
            const otpData = {
                UserId: userData?.UserId,
                CompanyCode: userData?.CompanyCode,
                Temp_Token: userData?.Temp_Token,
                otp: otp,
            };

            socket.emit('verifyOtp', otpData);

            socket.on('authResponse', (res) => {
                if (res && res.status === 1) {
                    handleLoginSuccess(res);
                } else {
                    setSankbar(true);
                    setSankbarText(res.message || "Invalid OTP.");
                    setSankbarColor("error");
                    setLoader(false);
                }
            });
        }
    }; 

    const handleKeyDownOtp = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleOtpVerification();
        }
    };

    const handleCreateAccount = (e) => {
        e.preventDefault();
        if (firstname === "" || firstname === null || firstname === undefined) {
            setFirstnameValid(true);
        } else if (lastname === "" || lastname === null || lastname === undefined) {
            setLastnameValid(true);
        } else if (email === "" || email === null || email === undefined) {
            setEmailValid(true);
        } else if (register_username === "" || register_username === null || register_username === undefined) {
            setRegister_usernameValid(true);
        } else if (register_mobilenumber === "" || register_mobilenumber === null || register_mobilenumber === undefined) {
            setRegister_mobilenumberValid(true);
        } else if (newpassword === "" || newpassword === null || newpassword === undefined) {
            setNewpasswordValid(true);
        } else if (confirmPassword === "" || confirmPassword === null || confirmPassword === undefined) {
            setConfirmPasswordValid(true);
        } else {
            setLoader(true);
            Post('User/InquiryAdd', {
                FirstName: firstname,
                LastName: lastname,
                UserEmail: email,
                Username: register_username,
                Pass: newpassword,
                UserMobile: register_mobilenumber,
                ConfirmPass: confirmPassword,
            }).then((res) => {
                setLoader(false);
                if (res) {
                    if (res.status === 1) {
                        // setType('signUp');
                        // onHandelReset();
                        setOtpStage(true);
                        setSankbar(true);
                        setSankbarText(res.message);
                        setSankbarColor("success");
                    } else {
                        setSankbar(true);
                        setSankbarText(res.message);
                        setSankbarColor("error");
                    }
                }
            });
        }
    }

    const handleOtpVerification2 = () => {
        if (!enteredWhatsappOtp) {
            setWhatsAppotpValid(true);
            return true;
        } else if (!enteredMailOtp) {
            setMailotpValid(true);
            return true;
        }

        setLoader(true);
        Post('User/InquiryAdd', {
            FirstName: firstname,
            LastName: lastname,
            UserEmail: email,
            Username: register_username,
            Pass: newpassword,
            UserMobile: register_mobilenumber,
            ConfirmPass: confirmPassword,
            enteredWhatsappOtp: enteredWhatsappOtp,
            enteredMailOtp: enteredMailOtp,
        }).then((res) => {
            setLoader(false);
            if (res?.status === 1) {
                setSankbarText("Account created successfully.");
                setSankbarColor("success");
                setSankbar(true);
                setSankbarText(res?.message);
                setLoader(false);
                setType('signUp');
                onHandelReset();
                setVisible(true);
            } else {
                setSankbarText("OTP verification failed.");
                setSankbar(true);
                setSankbarText(res?.message);
                setSankbarColor("error");
                setLoader(false);
            }
        });
    };

    const handleForgotPassword = () => {
        if (forgot_email === "" || forgot_email === null || forgot_email === undefined) {
            setForgot_emailValid(true);
        }

        Post('User/ForgotPassword', {
            UserEmail: forgot_email,
            CompanyCode: invalidCode,
        }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    handleLogin();
                    onHandelReset();
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("success");
                } else {
                    handleLoginFailure(res);
                    setSankbar(true);
                    setSankbarText(res.message);
                    setSankbarColor("error");
                    setLoader(false);
                }
            } else {
                setSankbar(true);
            }
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    const onHandleChange = (value, name) => {
        if (name === 'username') {
            setUsername(value);
            if (value) {
                setUsernameValid(false);
            } else {
                setUsernameValid(true);
            }
        } else if (name === "password") {
            setPassword(value);
            if (value) {
                setPasswordValid(false);
            } else {
                setPasswordValid(true);
            }
        } else if (name === "invalidCode") {
            setInvalidCode(value);
            if (value) {
                setInvalidCodeValid(false);
            } else {
                setInvalidCodeValid(true);
            }
        } else if (name === "otp") {
            setOtp(value);
            if (value) {
                setOtpValid(false);
            } else {
                setOtpValid(true);
            }
        } else if (name === "firstname") {
            setFirstname(value);
            if (value) {
                setFirstnameValid(false);
            } else {
                setFirstnameValid(true);
            }
        } else if (name === "lastname") {
            setLastname(value);
            if (value) {
                setLastnameValid(false);
            } else {
                setLastnameValid(true);
            }
        } else if (name === "emailaddress") {
            setEmail(value);
            if (value) {
                setEmailValid(false);
            } else {
                setEmailValid(true);
            }
        } else if (name === "reusername") {
            setRegister_username(value);
            if (value) {
                setRegister_usernameValid(false);
            } else {
                setRegister_usernameValid(true);
            }
        } else if (name === "mobilenumber") {
            setRegister_mobilenumber(value);
            if (value && value.length <= 10) {
                setRegister_mobilenumberValid(false);
            } else {
                setRegister_mobilenumberValid(true);
            }
        } else if (name === "newpassword") {
            setNewpassword(value);
            if (value) {
                setNewpasswordValid(false);
            } else {
                setNewpasswordValid(true);
            }
        } else if (name === "confirmpassword") {
            setConfirmPassword(value);
            if (value) {
                setConfirmPasswordValid(false);
            } else {
                setConfirmPasswordValid(true);
            }
        } else if (name === "forgot_emailaddress") {
            setForgot_email(value);
            if (value) {
                setForgot_emailValid(false);
            } else {
                setForgot_emailValid(true);
            }
        } else if (name === "whatsappOtp") {
            setEnteredWhatsappOtp(value);
            if (value) {
                setWhatsAppotpValid(false);
            } else {
                setWhatsAppotpValid(true);
            }
        } else if (name === "mailOtp") {
            setEnteredMailOtp(value);
            if (value) {
                setMailotpValid(false);
            } else {
                setMailotpValid(true);
            }
        }
    }

    const handleOnClick = (text) => {
        if (text !== type) {
            setType(text);
            return;
        }
    };
    const containerClass = "container " + (type === "signIn" ? "" : "right-panel-active");

    const onHandelReset = () => {
        setForgotPassword(false);
        setUsername('');
        setUsernameValid(false);
        setPassword('');
        setPasswordValid(false);
        setFirstname('');
        setFirstnameValid(false);
        setLastname('');
        setLastnameValid(false);
        setEmail('');
        setEmailValid(false);
        setRegister_username('');
        setRegister_usernameValid(false);
        setRegister_mobilenumber('');
        setRegister_mobilenumberValid(false);
        setNewpassword("");
        setNewpasswordValid(false);
        setConfirmPassword('');
        setConfirmPasswordValid(false);
        setForgot_email('');
        setForgot_emailValid(false);

        setInvalidCode('');
        setInvalidCodeValid(false);
        setOtp('');
        setOtpValid(false);
        setOtpSent(false);
        setUserData(null);
        setEnteredMailOtp('');
        setEnteredWhatsappOtp('');
        setOtpStage(false);
    }


    return (
        <div className={`login-container ${containerClass}`} id='Login'>
            <div className='form-container sign-up-container'>
                <div className='login-from '>
                    <div className='from-details'>
                        <div className='logo'>
                            <img src={logo} alt="Gadhiya technologies.com" />
                        </div>
                        <div className='sign-text'>
                            <h1>Sign in to Accohisab</h1>
                        </div>
                        {!forgotPassword ?
                            <div className="from">
                                {!otpSent ? (
                                    <>
                                        <div className="input">
                                            <FloatLabel>
                                                <InputText
                                                    keyfilter=''
                                                    value={invalidCode}
                                                    className={invalidCodeValid ? "p-invalid" : ""}
                                                    onChange={(e) => onHandleChange(e.target.value, 'invalidCode')}
                                                    name="company code"
                                                    id="InvalidCode"
                                                    autoFocus={true}
                                                />
                                                <label htmlFor="InvalidCode">Company Code</label>
                                            </FloatLabel>
                                        </div>
                                        <div className="input">
                                            <FloatLabel>
                                                <InputText
                                                    keyfilter='alphanum'
                                                    value={username}
                                                    className={usernameValid ? "p-invalid" : ""}
                                                    onChange={(e) => onHandleChange(e.target.value, 'username')}
                                                    name="User Name"
                                                    id="username"
                                                />
                                                <label htmlFor="username">User Name</label>
                                            </FloatLabel>
                                        </div>

                                        <div className="input">
                                            <FloatLabel>
                                                <Password
                                                    onChange={(e) => onHandleChange(e.target.value, 'password')}
                                                    value={password}
                                                    className={passwordValid ? "p-invalid" : ""}
                                                    name={"Password"}
                                                    id={"password"}
                                                    feedback={false}
                                                    onKeyDown={handleKeyDown}
                                                    toggleMask
                                                />
                                                <label htmlFor="password">Password</label>
                                            </FloatLabel>
                                        </div>

                                        <Button label="Login" severity="secondary" type="submit" onClick={() => handleLogin()} />
                                    </>
                                ) : (
                                    <>
                                        <div className="input">
                                            <FloatLabel>
                                                <InputText
                                                    keyfilter='num'
                                                    value={otp}
                                                    className={otpValid ? "p-invalid" : ""}
                                                    onChange={(e) => onHandleChange(e.target.value, 'otp')}
                                                    name="OTP"
                                                    id="otp"
                                                    maxLength={6}
                                                    onKeyDown={handleKeyDownOtp}
                                                    autoFocus={true}
                                                />
                                                <label htmlFor="otp">Enter OTP</label>
                                            </FloatLabel>
                                        </div>
                                        <Button label="Verify OTP" severity="secondary" type="submit" onClick={() => handleOtpVerification()} />
                                    </>
                                )}
                                <div className='ForgotPassword'>
                                    <button className="Forgot" onClick={() => setForgotPassword(true)}>Forgot password?</button>
                                </div>  

                            </div>

                            :
                            <div className="from register">
                                <div className="input">
                                    <FloatLabel>
                                        <InputText
                                            keyfilter='email'
                                            value={forgot_email}
                                            className={forgot_emailValid ? 'p-invalid' : ""}
                                            onChange={(e) => onHandleChange(e.target.value, "forgot_emailaddress")}
                                            name="Email address"
                                            id="emailaddress"
                                            autoFocus={true}
                                        />
                                        <label htmlFor="email">Email address</label>
                                    </FloatLabel>
                                </div>
                                <div className="input">
                                    <FloatLabel>
                                        <InputText
                                            keyfilter=''
                                            value={invalidCode}
                                            className={invalidCodeValid ? "p-invalid" : ""}
                                            onChange={(e) => onHandleChange(e.target.value, 'invalidCode')}
                                            name="User Name"
                                            id="InvalidCode"
                                            autoFocus={true}
                                        />
                                        <label htmlFor="InvalidCode">Company Code</label>
                                    </FloatLabel>
                                </div>
                                <Button
                                    label="Forgot password"
                                    severity="secondary"
                                    type="submit"
                                    onClick={() => handleForgotPassword()}
                                />
                                <div className="return-signin">
                                    <button onClick={() => setForgotPassword(false)}>Return Sign in</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="foter-title">
                    <span onClick={() => window.open('https://gadhiyatechnologies.com', '_blank')}> Developed by Gadhiya Technologies</span>
                </div>
            </div>


            <div className='form-container sign-in-container'>
                <div className='register-from'>
                    <div className='logo'>
                        <img src={logo} alt="Responsive Logo" />
                    </div>
                    <div className='sign-text'>
                        <h1>Inquiry Form</h1>
                    </div>
                    {!otpStage ? (
                        <form onSubmit={handleCreateAccount} className='d-block' autoComplete={'off'}>
                            <div className="from register">
                                <div className="name">
                                    <div className="input">
                                        <FloatLabel>
                                            <InputText
                                                keyfilter='alphanum'
                                                value={firstname}
                                                className={firstnameValid ? 'p-invalid' : ""}
                                                onChange={(e) => onHandleChange(e.target.value, "firstname")}
                                                name="First name"
                                                id="firstname"
                                                autoFocus={firstnameValid}
                                            />
                                            <label htmlFor="username">First name</label>
                                        </FloatLabel>
                                    </div>

                                    <div className="input">
                                        <FloatLabel>
                                            <InputText
                                                keyfilter='alphanum'
                                                value={lastname}
                                                className={lastnameValid ? 'p-invalid' : ""}
                                                onChange={(e) => onHandleChange(e.target.value, "lastname")}
                                                name="Last name"
                                                id="lastname"
                                                autoFocus={lastnameValid}
                                            />
                                            <label htmlFor="username">Last name</label>
                                        </FloatLabel>
                                    </div>
                                </div>

                                <div className="input">
                                    <FloatLabel>
                                        <InputText
                                            keyfilter='email'
                                            value={email}
                                            className={emailValid ? 'p-invalid' : ""}
                                            onChange={(e) => onHandleChange(e.target.value, "emailaddress")}
                                            name="Email address"
                                            id="emailaddress"
                                            autoFocus={emailValid}
                                        />
                                        <label htmlFor="email">Email address</label>
                                    </FloatLabel>
                                </div>

                                <div className="input">
                                    <FloatLabel>
                                        <InputText
                                            keyfilter='alphanum'
                                            value={register_username}
                                            className={register_usernameValid ? 'p-invalid' : ""}
                                            onChange={(e) => onHandleChange(e.target.value, "reusername")}
                                            name="User name"
                                            id="reusername"
                                            autoFocus={register_usernameValid}
                                        />
                                        <label htmlFor="username">User Name</label>
                                    </FloatLabel>
                                </div>

                                <div className="input">
                                    <FloatLabel>
                                        <InputText
                                            keyfilter={'num'}
                                            onChange={(e) => onHandleChange(e.target.value, "mobilenumber")}
                                            name="Mobile number"
                                            id="mobilenumber"
                                            value={register_mobilenumber}
                                            className={register_mobilenumberValid ? 'p-invalid' : ""}
                                            maxLength={10}
                                            autoFocus={register_mobilenumberValid}
                                        />
                                        <label htmlFor="mobilenumber">Mobile number</label>
                                    </FloatLabel>
                                </div>

                                <div className="input">
                                    <FloatLabel>
                                        <Password
                                            onChange={(e) => onHandleChange(e.target.value, "newpassword")}
                                            value={newpassword}
                                            className={newpasswordValid ? 'p-invalid' : ""}
                                            name={"New password"}
                                            id={"newpassword"}
                                            feedback={false}
                                            toggleMask
                                            autoFocus={newpasswordValid}
                                        />
                                        <label htmlFor="password">New password</label>
                                    </FloatLabel>
                                </div>

                                <div className="input">
                                    <FloatLabel>
                                        <Password
                                            onChange={(e) => onHandleChange(e.target.value, "confirmpassword")}
                                            value={confirmPassword}
                                            className={confirmPasswordValid ? 'p-invalid' : ""}
                                            name={"Confirm password"}
                                            id={"confirmpassword"}
                                            feedback={false}
                                            toggleMask
                                            autoFocus={newpasswordValid}
                                        />
                                        <label htmlFor="password">Confirm password</label>
                                    </FloatLabel>
                                </div>
                                <Button label="Submit" severity="secondary" type="submit" />
                            </div>
                        </form>
                    ) : (
                        <div className="otp-verification">
                            <div className="input">
                                <FloatLabel>
                                    <InputText
                                        value={enteredWhatsappOtp}
                                        onChange={(e) => onHandleChange(e.target.value, 'whatsappOtp')}
                                        name="WhatsApp OTP"
                                        className={whatsAppotpValid ? "p-invalid" : ""}
                                        id="whatsappOtp"
                                    />
                                    <label htmlFor="whatsappOtp">Enter WhatsApp OTP</label>
                                </FloatLabel>
                            </div>
                            <div className="input">
                                <FloatLabel>
                                    <InputText
                                        value={enteredMailOtp}
                                        onChange={(e) => onHandleChange(e.target.value, 'mailOtp')}
                                        className={mailotpValid ? "p-invalid" : ""}
                                        name="Mail OTP"
                                        id="mailOtp"
                                    />
                                    <label htmlFor="mailOtp">Enter Email OTP</label>
                                </FloatLabel>
                            </div>
                            <Button label="Verify OTP" onClick={handleOtpVerification2} severity="primary" />
                        </div>
                    )}
                </div>

                <div className="foter-title">
                    <span onClick={() => window.open('https://gadhiyatechnologies.com', '_blank')}> Developed by Gadhiya Technologies</span>
                </div>
            </div>


            <div className="overlay-container">
                <div className="overlay">
                    <div className="overlay-panel overlay-left">
                        <h1>Welcome Back!</h1>
                        <p> Access your personal dashboard and manage your accounts effortlessly with Accohisab.   </p>
                        <Button className="ghost" id="signIn" onClick={() => { handleOnClick("signIn"); onHandelReset(); }}>Inquiry</Button>
                    </div>
                    <div className="overlay-panel overlay-right">
                        <h1>Join Us Today!</h1>
                        <p>Create your Accohisab account and start simplifying your sales management today!</p>
                        <Button className="ghost " id="signUp" onClick={() => { handleOnClick("signUp"); onHandelReset(); }} > Sign In </Button>
                    </div>
                </div>
            </div>

            <Dialog header="Message" style={{ width: '375px' }} visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} id='meassge-box'>
                <div className=''>
                    <p>Hello dear,</p>
                    <br />
                    <p>
                        Thank you for submitting your inquiry. We have received your details and our team is reviewing it.
                        You will get a response shortly via email.</p>
                </div>
            </Dialog>

            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />

            {loader ? <Loader /> : ""}
        </div>
    );
};

export default Login;